/* eslint-disable react-hooks/exhaustive-deps */
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Avatar,
  AvatarBadge,
  Box,
  Button,
  Center,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Heading,
  IconButton,
  Input,
  InputGroup,
  InputRightAddon,
  SimpleGrid,
  Spinner,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import axios from 'axios'
import { db, storage } from 'config/firebase'
import {
  addDoc,
  collection,
  doc,
  increment,
  updateDoc,
  writeBatch,
} from 'firebase/firestore'
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage'
import moment from 'moment'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { AiFillCamera } from 'react-icons/ai'
import { BiArrowBack } from 'react-icons/bi'
import { RiPrinterFill } from 'react-icons/ri'
import Resizer from 'react-image-file-resizer'
import { Link, useNavigate, useParams } from 'react-router-dom'

import FormInputs from 'components/DynamicForm'
import useBranch from 'hooks/useBranch'
import useKid from 'hooks/useKid'
import usePayment from 'hooks/usePayment'
import randomId from 'random-id'
import KidsKnowForm from './Compoments/knowForm'
import {
  backgroundForm,
  infoForm1,
  infoForm2,
  parent1Form,
  parent2Form,
  parentExtraForm,
} from './fields'

const resizeFile = (file) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      300,
      300,
      'jpg',
      100,
      0,
      (uri) => {
        resolve(uri)
      },
      'file'
    )
  })

export default function KidForm({ managerBranchId, role, user, kid }) {
  const navigate = useNavigate()
  const { id } = useParams()
  const toast = useToast()
  const [isLoading, setLoading] = useState(false)
  const [data, setdata] = useState()
  const [fileUpload, setFileUpload] = useState(null)
  const hiddenFileInput = useRef()
  const [branchesQuery, setBranchesQuery] = useState([])
  const { isOpen, onOpen, onClose } = useDisclosure()
  const cancelRef = useRef()
  const { createPDFProfile } = useKid()
  const { branches } = useBranch()
  const { getDownloadURLToken } = usePayment()
  const [generating, setGenerating] = useState(false)

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    getValues,
    watch,
    setValue,
  } = useForm({
    reValidateMode: 'onChange',
  })

  const watchBirthday = watch(`birthday`)
  const watchBranch = watch(`branchId`)
  const watchKnow = watch(`know`)

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
    if (kid) {
      setdata({
        ...kid,
        id: kid.id,
      })
    }
  }, [kid])

  useEffect(() => {
    if (managerBranchId && branches) {
      setBranchesQuery(
        branches.filter((branch) => branch.id === managerBranchId)
      )
      setValue('branchId', managerBranchId)
    } else {
      setBranchesQuery(branches)
    }
  }, [managerBranchId, branches])

  const branchForm = [
    {
      label: 'สาขา / BRANCH',
      name: 'branchId',
      type: 'dropdown',
      options: branchesQuery?.map((doc) => ({
        value: doc.id,
        label: doc.name,
      })),
      placeholder: '',
      defaultValue: '',
      required: true,
      disabled: managerBranchId,
    },
  ]

  const age = useMemo(() => {
    const birthday = getValues('birthday') || data?.birthday

    var a = moment()
    var b = moment(birthday, 'YYYY-MM-DD')
    var age = moment.duration(a.diff(b))
    var years = age.years()
    var months = age.months()

    if (!isNaN(years) && !isNaN(months)) {
      return `${years.toFixed()} ปี ${months} เดือน`
    } else {
      return ''
    }
  }, [watchBirthday, data?.birthday])

  function submit(values) {
    setLoading(true)
    if (data?.id) {
      updateKid(values)
    } else {
      createKid(values)
    }
  }

  function handleUploadClick() {
    hiddenFileInput.current.click()
  }

  async function handleUploadChange(e) {
    e.preventDefault()
    const fileUploaded = e.target.files[0]
    if (
      fileUploaded?.type === 'image/jpeg' ||
      fileUploaded?.type === 'image/png'
    ) {
      const resizeFileUpload = await resizeFile(fileUploaded)
      setFileUpload(resizeFileUpload)
    } else {
      setFileUpload(null)
      toast({
        Header: 'อัพโหลดไฟล์',
        position: 'top',
        description: 'ใช้ไฟล์รูปภาพ JPG, JPEG เท่านั้น',
        type: 'info',
        duration: 4000,
        isClosable: true,
      })
    }
  }

  const downloadPdf = async () => {
    setGenerating(true)
    const { data } = await createPDFProfile({ id })
    const downloadUrl = await getDownloadURLToken(data.pdfRef)
    handleDownload(downloadUrl, data.pdfRef)
  }

  const handleDownload = async (url, name) => {
    try {
      await axios({
        url,
        method: 'GET',
        responseType: 'blob',
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `${name}`)
        document.body.appendChild(link)
        link.click()
      })
    } catch (error) {
      console.log(error)
    } finally {
      setGenerating(false)
    }
  }

  async function createKid(data) {
    try {
      const batch = writeBatch(db)
      const values = await JSON.parse(JSON.stringify(data))

      if (values?.branch) {
        const selectBranch = branchesQuery.find(
          (branches) => branches.id === values?.branchId
        )
        const branchRef = doc(db, 'Branches', selectBranch?.id)
        batch.set(branchRef, { counters: increment(1) }, { merge: true })
      }

      if (fileUpload) {
        const kid = await addDoc(collection(db, 'Kids'), {
          ...values,
          birthday: values?.birthday
            ? new Date(moment(values.birthday, 'YYYY-MM-DD'))
            : '',
          createdAt: new Date(),
          status: 'evaluation',
        })

        const promises = []
        const storageRef = ref(storage, `Kids/${kid.id}`)
        const uploadTask = uploadBytes(storageRef, fileUpload)

        promises.push(
          uploadTask
            .then((uploadResult) => {
              return getDownloadURL(uploadResult.ref)
            })
            .then((url) => url)
        )

        const downloadURL = await Promise.all(promises)

        const kidRef = doc(db, 'Kids', kid.id)
        createPDFProfile({ id: kid.id })

        batch.set(kidRef, {
          ...values,
          birthday: values?.birthday
            ? new Date(moment(values.birthday, 'YYYY-MM-DD'))
            : '',
          createdAt: new Date(),
          createdBy: user.uid,
          picture: downloadURL,
          status: 'evaluation',
        })
      } else {
        const kid = await addDoc(collection(db, 'Kids'), {
          ...values,
          birthday: values?.birthday
            ? new Date(moment(values.birthday, 'YYYY-MM-DD'))
            : '',
          createdAt: new Date(),
          createdBy: user.uid,
          status: 'evaluation',
        })
        createPDFProfile({ id: kid.id })
      }

      await batch.commit()
      setLoading(false)
      toast({
        position: 'top',
        Header: 'Create Kid',
        description: 'Kid has been created.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      })
      navigate('/kids')
    } catch (e) {
      setLoading(false)
      toast({
        position: 'top',
        Header: 'Create Fail.',
        description: e.message,
        status: 'error',
        duration: 3000,
        isClosable: true,
      })
    }
  }

  async function updateKid(data) {
    try {
      const values = await JSON.parse(JSON.stringify(data))

      const batch = writeBatch(db)

      const kidRef = doc(db, 'Kids', `${id}`)

      const selectBranch = branchesQuery.find(
        (branches) => branches.id === values?.branchId
      )

      if (fileUpload) {
        const promises = []
        const storageRef = ref(storage, `Kids/${id}`)
        const uploadTask = uploadBytes(storageRef, fileUpload)

        promises.push(
          uploadTask
            .then((uploadResult) => {
              return getDownloadURL(uploadResult.ref)
            })
            .then((url) => url)
        )

        const downloadURL = await Promise.all(promises)

        batch.set(
          kidRef,
          {
            ...values,
            birthday: values?.birthday
              ? new Date(moment(values.birthday, 'YYYY-MM-DD'))
              : '',
            updatedAt: new Date(),
            updatedBy: user?.uid,
            picture: downloadURL,
            branchId: selectBranch?.id || '',
          },
          { merge: true }
        )
      } else {
        batch.set(
          kidRef,
          {
            ...values,
            birthday: values?.birthday
              ? new Date(moment(values.birthday, 'YYYY-MM-DD'))
              : '',
            updatedAt: new Date(),
            updatedBy: user?.uid,
            branchId: selectBranch?.id || '',
          },
          { merge: true }
        )
      }

      createPDFProfile({ id })
      await batch.commit()
      setLoading(false)
      toast({
        position: 'top',
        Header: 'Update Kid',
        description: 'Kid has been updated.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      })
      // navigate('/kids')
    } catch (e) {
      setLoading(false)
      toast({
        position: 'top',
        Header: 'Update Fail.',
        description: e.message,
        status: 'error',
        duration: 3000,
        isClosable: true,
      })
    }
  }

  // if (isLoadingData || (id && !data)) {
  //   return <Center h='100vh'></Center>
  // }
  const generatingData = () => {
    const randomCode = randomId(6)
    const kidRef = doc(db, 'Kids', `${id}`)
    setValue('crmCode', randomCode)
    updateDoc(kidRef, { crmCode: randomCode })
  }

  return (
    <>
      <AlertDialog
        motionPreset='slideInBottom'
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isOpen={isOpen}
        isCentered
      >
        <AlertDialogOverlay />

        <AlertDialogContent>
          <AlertDialogHeader>Discard Changes?</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>
            Are you sure you want to discard all of your input
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={onClose}>
              No
            </Button>
            <Link to={'/kids'}>
              <Button colorScheme='red' ml={3}>
                Yes
              </Button>
            </Link>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
      {(id && data) || !id ? (
        <Box p={id ? 0 : 10}>
          <Center my={5}>
            <Heading>APPLICATION</Heading>
          </Center>
          {!id && (
            <Flex justifyContent='flex-start'>
              <IconButton
                size={30}
                variant='ghost'
                color='brand'
                icon={<BiArrowBack size={30} />}
                onClick={() => navigate('/kids')}
              />
            </Flex>
          )}

          <Flex justifyContent='space-between' mb={2} alignItems='flex-end'>
            <Heading as='h5' size='sm' color='brand'>
              Basic Information
            </Heading>
            {id ? (
              <IconButton
                position={'absolute'}
                right={14}
                size={40}
                variant='ghost'
                color='brand'
                isDisabled={generating}
                icon={
                  generating ? (
                    <Spinner size='lg' />
                  ) : (
                    <RiPrinterFill size={40} />
                  )
                }
                onClick={() => downloadPdf()}
              />
            ) : null}
          </Flex>
          <Box
            p={5}
            borderRadius={10}
            mb={10}
            borderWidth='1px'
            borderColor='brand'
          >
            <Center my={8}>
              {fileUpload ? (
                <Avatar
                  src={URL.createObjectURL(fileUpload)}
                  cursor='pointer'
                  bg='gray.200'
                  size='2xl'
                  onClick={handleUploadClick}
                >
                  <AvatarBadge boxSize='1.2em' border={0}>
                    <AiFillCamera fontSize={'25px'} color='green' />
                  </AvatarBadge>
                </Avatar>
              ) : data?.picture ? (
                <Avatar
                  src={data?.picture}
                  cursor='pointer'
                  bg='gray.200'
                  size='2xl'
                  onClick={handleUploadClick}
                >
                  <AvatarBadge boxSize='1.2em' border={0}>
                    <AiFillCamera fontSize={'25px'} color='green' />
                  </AvatarBadge>
                </Avatar>
              ) : (
                <Avatar
                  cursor='pointer'
                  bg='gray.200'
                  size='2xl'
                  onClick={handleUploadClick}
                >
                  <AvatarBadge boxSize='1.2em' border={0}>
                    <AiFillCamera fontSize={'25px'} color='green' />
                  </AvatarBadge>
                </Avatar>
              )}
              <Input
                type='file'
                display='none'
                ref={hiddenFileInput}
                onChange={handleUploadChange}
              />
            </Center>

            <Box>
              <SimpleGrid
                columns={[1, 1, 1, 2]}
                spacingX={[1, 1, 1, '25px']}
                justifyContent='center'
                alignItems='center'
              >
                <FormInputs
                  errors={errors}
                  control={control}
                  forms={branchForm || []}
                  data={data}
                />
                {role !== 'Staff' && (
                  <FormControl my={3} w={'100%'}>
                    <FormLabel>
                      <HStack spacing={0}>
                        <Text>CRM Connect Code</Text>
                      </HStack>
                    </FormLabel>
                    <InputGroup>
                      <Input
                        {...register(`crmCode`)}
                        placeholder='Please Generate'
                        name='crmCode'
                        readOnly
                        defaultValue={kid?.crmCode}
                      />
                      <InputRightAddon p={0} m={0}>
                        <Button
                          colorScheme='lime'
                          bg='brand'
                          size='sm'
                          w='full'
                          h='full'
                          borderRadius='sm'
                          onClick={() => generatingData()}
                        >
                          Generate
                        </Button>
                      </InputRightAddon>
                    </InputGroup>
                  </FormControl>
                )}
              </SimpleGrid>
              <SimpleGrid
                columns={[1, 1, 1, 2]}
                spacingX={[1, 1, 1, '25px']}
                justifyContent='center'
                alignItems='center'
              >
                <FormInputs
                  errors={errors}
                  control={control}
                  forms={infoForm1(role) || []}
                  data={data}
                />

                <FormControl id={`age`} my={3}>
                  <FormLabel>อายุ / AGE</FormLabel>
                  <Input name={`age`} type={'text'} value={age} readOnly />
                </FormControl>
                <FormInputs
                  errors={errors}
                  control={control}
                  forms={infoForm2(role) || []}
                  data={data}
                />
              </SimpleGrid>
            </Box>
          </Box>
          <Heading as='h5' size='sm' my={2} color='brand'>
            Background
          </Heading>
          <Box
            p={5}
            borderRadius={10}
            mb={10}
            borderWidth='1px'
            borderColor='brand'
          >
            <Box>
              <FormInputs
                errors={errors}
                control={control}
                forms={
                  [
                    {
                      label: 'ที่อยู่ / ADDRESS',
                      name: 'address',
                      type: 'textarea',
                      placeholder: '',
                      defaultValue: '',
                      required: false,
                      readonly: role === 'Staff',
                    },
                  ] || []
                }
                data={data}
              />
              <SimpleGrid
                columns={[1, 1, 2, 2]}
                spacingX={[1, 1, 1, '25px']}
                justifyContent='center'
                alignItems='center'
              >
                <FormInputs
                  errors={errors}
                  control={control}
                  forms={backgroundForm(role) || []}
                  data={data}
                />
              </SimpleGrid>

              <FormInputs
                errors={errors}
                control={control}
                forms={
                  [
                    {
                      label: 'ของเล่นที่ชอบ/ความสนใจ / FAVORITE TOYS/HOBBY',
                      name: 'hobbie',
                      type: 'text',
                      defaultValue: '',
                      readonly: role === 'Staff',
                      required: false,
                    },
                  ] || []
                }
                data={data}
              />

              {/* <SimpleGrid
                columns={[1, 1, 2, 2]}
                spacingX={[1, 1, 1, '25px']}
                justifyContent='center'
                alignItems='center'
              >
                <FormInputs
                  errors={errors}
                  control={control}
                  forms={knowKidsPlusForm(role) || []}
                  data={data}
                />
              </SimpleGrid> */}
              <KidsKnowForm
                role={role}
                control={control}
                errors={errors}
                data={data}
                watchKnow={watchKnow}
                register={register}
              />
            </Box>
          </Box>
          <Heading as='h5' size='sm' my={2} color='brand'>
            Parent
          </Heading>
          <Box
            p={5}
            borderRadius={10}
            mb={10}
            borderWidth='1px'
            borderColor='brand'
          >
            <SimpleGrid
              columns={[1, 1, 2, 2]}
              spacingX={[1, 1, 1, '25px']}
              justifyContent='center'
              alignItems='center'
            >
              <Box>
                <Heading as='h6' size='sm' my={2} color='brand'>
                  ผู้ปกครอง 1
                </Heading>
                <FormInputs
                  errors={errors}
                  control={control}
                  forms={parent1Form(role) || []}
                  data={data}
                />
              </Box>
              <Box>
                <Heading as='h6' size='sm' my={2} color='brand'>
                  ผู้ปกครอง 2
                </Heading>
                <FormInputs
                  errors={errors}
                  control={control}
                  forms={parent2Form(role) || []}
                  data={data}
                />
              </Box>
              <Box>
                <Heading as='h6' size='sm' my={2} color='#F6872B'>
                  ข้อมูลการติดต่อกรณีฉุกเฉิน นอกเหนือจากผู้ปกครองหลัก
                </Heading>
                <FormInputs
                  errors={errors}
                  control={control}
                  forms={parentExtraForm(role) || []}
                  data={data}
                />
              </Box>
            </SimpleGrid>
          </Box>

          {role !== 'Staff' && (
            <HStack justifyContent='center' my={10}>
              <Button mr={3} variant='outline' onClick={onOpen}>
                Cancel
              </Button>
              <Button
                colorScheme='lime'
                bg='brand'
                onClick={handleSubmit(submit)}
                isLoading={isLoading}
                isDisabled={(id && !data) || !watchBranch || watchBranch === ''}
              >
                Submit
              </Button>
            </HStack>
          )}
        </Box>
      ) : (
        <Center h='70vh'>
          <div className='custom-loader' />
        </Center>
      )}
    </>
  )
}
