import { useMemo } from 'react'
import {
  Box,
  Tab,
  Tabs,
  TabList,
  TabPanels,
  TabPanel,
  Flex,
  IconButton,
  HStack,
  Text,
  Skeleton,
} from '@chakra-ui/react'
import { BiArrowBack } from 'react-icons/bi'
import {
  useNavigate,
  useParams,
  useSearchParams,
  useLocation,
} from 'react-router-dom'

import KidForm from 'pages/Kids/form'
import Office from 'pages/Kids/Office'
import Development from 'pages/Kids/Development'
import Course from 'pages/Kids/Course'
import KidPayment from './Payment'
import KidDocument from './Document'
import useBranch from 'hooks/useBranch'
import { useDocument } from '@lemasc/swr-firestore'
import moment from 'moment'

export default function KidInfo({ user, role }) {
  const { id } = useParams()
  const { state } = useLocation()
  const { branches } = useBranch()
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()

  const managerBranchId =
    role === 'Manager' || role === 'Staff' ? user?.branchId : null

  const tab = useMemo(() => {
    return Number(searchParams.get('tab'))
  }, [searchParams])

  const { data: kidData, isLoading } = useDocument(`Kids/${id}`, {
    listen: true,
  })

  const kid = useMemo(() => {
    if (state?.kidData) {
      console.log('use state data')
      return {
        ...state?.kidData,
        know: state?.kidData?.know || kidData?.know || null,
      }
    } else if (kidData) {
      console.log('load this data')
      return {
        ...kidData,
        birthday:
          kidData.birthday && kidData.birthday !== ''
            ? moment(kidData.birthday.toDate()).format('YYYY-MM-DD')
            : undefined,
        telephone: kidData?.telephone
          ? kidData?.telephone.replace('-', '')
          : '',
        parentTel1: kidData?.parentTel1
          ? kidData?.parentTel1.replace('-', '')
          : '',
        parentTel2: kidData?.parentTel2
          ? kidData?.parentTel2.replace('-', '')
          : '',
        parentTelExtra: kidData?.parentTelExtra
          ? kidData?.parentTelExtra.replace('-', '')
          : '',
      }
    }
    return null
  }, [state?.kidData, kidData])

  const branchName = useMemo(() => {
    if (kid && branches) {
      const branch = branches.find(
        (b) => b.id === kid.branch?.id || b.id === kid.branchId
      )
      return branch?.name
    }
    return ''
  }, [kid, branches])

  return (
    <Box p={{ base: 4, lg: 10 }}>
      <Flex justifyContent='space-between'>
        <Flex flex={1}>
          <IconButton
            size={30}
            variant='ghost'
            color='brand'
            icon={<BiArrowBack size={30} />}
            onClick={() => navigate('/kids')}
          />
        </Flex>
        <Flex flex={2} alignItems='center'>
          <Skeleton isLoaded={state?.kidData || kid} minW={200}>
            <HStack spacing={2}>
              <HStack spacing={1}>
                <Box
                  fontWeight='semibold'
                  as='h6'
                  lineHeight='tight'
                  fontSize={{ md: 'sm', lg: 'md' }}
                  display={{ base: 'none', md: 'block' }}
                >
                  OFFICE :
                </Box>
                <Box fontSize={{ md: 'sm', lg: 'md' }}>{branchName || ''}</Box>
              </HStack>
              <HStack spacing={1} fontSize={{ md: 'sm', lg: 'md' }}>
                <Box
                  fontWeight='semibold'
                  as='h5'
                  lineHeight='tight'
                  display={{ base: 'none', md: 'block' }}
                >
                  KID CODE :
                </Box>

                <Text>{`${kid?.code || 'Evaluation'}`}</Text>
              </HStack>
              <HStack spacing={1} fontSize={{ md: 'sm', lg: 'md' }}>
                <Box
                  fontWeight='semibold'
                  as='h5'
                  lineHeight='tight'
                  display={{ base: 'none', md: 'block' }}
                >
                  KID'S NAME :
                </Box>
                <Text fontSize={{ md: 'sm', lg: 'md' }}>{`${kid?.name || ''} (${
                  kid?.nickname || ''
                })`}</Text>
              </HStack>
            </HStack>
          </Skeleton>
        </Flex>
        <Flex></Flex>
      </Flex>
      <Box>
        <Tabs
          variant='solid-rounded'
          mt={'2rem'}
          colorScheme='lime'
          onChange={(i) => setSearchParams({ tab: i })}
          index={tab}
        >
          <TabList overflowX='auto'>
            <Tab
              _focus={{ boxShadow: 'none' }}
              _selected={{ color: 'white', bg: 'brand' }}
              borderBottom='2px solid rgba(226, 232, 240, 1)'
              borderRadius='15px'
              mx={1}
            >
              KID INFO
            </Tab>
            <Tab
              _focus={{ boxShadow: 'none' }}
              _selected={{ color: 'white', bg: 'brand' }}
              borderBottom='2px solid rgba(226, 232, 240, 1)'
              borderRadius='15px'
              mx={1}
            >
              DEVELOPMENT
            </Tab>
            {kid?.status !== 'evaluation' && (
              <Tab
                _focus={{ boxShadow: 'none' }}
                _selected={{ color: 'white', bg: 'brand' }}
                borderBottom='2px solid rgba(226, 232, 240, 1)'
                borderRadius='15px'
                mx={1}
              >
                FOR OFFICE
              </Tab>
            )}
            <Tab
              _focus={{ boxShadow: 'none' }}
              _selected={{ color: 'white', bg: 'brand' }}
              borderBottom='2px solid rgba(226, 232, 240, 1)'
              borderRadius='15px'
              mx={1}
            >
              PROGRAMS
            </Tab>

            {role !== 'Staff' && role !== 'CoAdmin' && (
              <Tab
                _focus={{ boxShadow: 'none' }}
                _selected={{ color: 'white', bg: 'brand' }}
                borderBottom='2px solid rgba(226, 232, 240, 1)'
                borderRadius='15px'
                mx={1}
              >
                PAYMENTS
              </Tab>
            )}
            <Tab
              _focus={{ boxShadow: 'none' }}
              _selected={{ color: 'white', bg: 'brand' }}
              borderBottom='2px solid rgba(226, 232, 240, 1)'
              borderRadius='15px'
              mx={1}
            >
              DOCUMENTS
            </Tab>
          </TabList>

          <TabPanels>
            <TabPanel>
              <KidForm
                kid={kid}
                user={user}
                managerBranchId={managerBranchId}
                role={role}
              />
            </TabPanel>
            <TabPanel>
              <Development
                kid={kid}
                user={user}
                managerBranchId={managerBranchId}
                role={role}
                isLoading={isLoading}
              />
            </TabPanel>
            {kid?.status !== 'evaluation' && (
              <TabPanel>
                <Office
                  kid={kid}
                  role={role}
                  isLoading={isLoading}
                  user={user}
                />
              </TabPanel>
            )}

            <TabPanel>
              <Course
                user={user}
                kid={kid}
                managerBranchId={managerBranchId}
                role={role}
                isLoading={isLoading}
              />
            </TabPanel>

            {role !== 'Staff' && role !== 'CoAdmin' && (
              <TabPanel>
                <KidPayment user={user} role={role} />
              </TabPanel>
            )}
            <TabPanel>
              <KidDocument user={user} role={role} />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
    </Box>
  )
}
