const infoForm1 = (role) => [
  {
    label: `ชื่อ - นามสกุล เด็ก`,
    name: 'name',
    type: 'text',
    placeholder: '',
    defaultValue: '',
    readonly: role === 'Staff',
    // required: true,
  },
  {
    label: `KID'S FULL NAME`,
    name: 'nameEn',
    type: 'text',
    placeholder: '',
    defaultValue: '',
    readonly: role === 'Staff',
    // required: true,
  },
  {
    label: `ชื่อเล่น / NICKNAME`,
    name: 'nickname',
    type: 'text',
    placeholder: '',
    defaultValue: '',
    required: true,
    readonly: role === 'Staff',
  },
  {
    label: `เบอร์ติดต่อ / PHONE NUMBER`,
    name: 'telephone',
    type: 'number',
    placeholder: '',
    defaultValue: '',
    required: true,
    readonly: role === 'Staff',
    rules: {
      maxLength: {
        value: 10,
        message: 'เบอร์โทรศัพท์ 10 หลักเท่านั้น',
      },
      minLength: {
        value: 10,
        message: 'เบอร์โทรศัพท์ 10 หลักเท่านั้น',
      },
    },
  },

  {
    label: `ว/ด/ป เกิด / DOB`,
    name: 'birthday',
    type: 'date',
    placeholder: '',
    defaultValue: '',
    readonly: role === 'Staff',
    // required: true,
  },
]

const infoForm2 = (role) => [
  {
    label: 'สัญชาติ / NATIONALITY',
    name: 'nationality',
    type: 'text',
    placeholder: '',
    defaultValue: '',
    readonly: role === 'Staff',

    // required: true,
  },
  {
    label: `เชื้อชาติ / ETHNICITY`,
    name: 'ethnicity',
    type: 'text',
    placeholder: '',
    defaultValue: '',
    readonly: role === 'Staff',

    // required: true,
  },
  {
    label: `ศาสนา / RELIGION`,
    name: 'religion',
    type: 'text',
    placeholder: '',
    defaultValue: '',
    readonly: role === 'Staff',

    // required: true,
  },
  {
    label: `เลขที่บัตรประชาชน / ID NO. / PASSPORT NO.`,
    name: 'idCard',
    type: 'number',
    placeholder: '',
    defaultValue: '',
    readonly: role === 'Staff',
    // required: true,
    // rules: {
    //   maxLength: {
    //     value: 13,
    //     message: 'เลขที่บัตรประชาชน 13 หลักเท่านั้น',
    //   },
    //   minLength: {
    //     value: 13,
    //     message: 'เลขที่บัตรประชาชน 13 หลักเท่านั้น',
    //   },
    // },
  },
  {
    label: `จำนวนพี่น้อง / SIBLING`,
    name: 'sibling',
    type: 'number-format',
    placeholder: '',
    defaultValue: '',
    readonly: role === 'Staff',
    // required: true,
  },
  {
    label: `เพศ / GENDER`,
    name: 'gender',
    type: 'radio',
    options: ['ชาย', 'หญิง'],
    placeholder: '',
    defaultValue: '',
    disabled: role === 'Staff',
    // required: true,
  },
  {
    label: `มือข้างถนัด DOMINANT HAND`,
    name: 'dominantHand',
    type: 'checkbox',
    options: ['ขวา', 'ซ้าย'],
    placeholder: '',
    defaultValue: [],
    disabled: role === 'Staff',
  },
  {
    label: `First Treatment Date`,
    name: 'firstTreatmentDate',
    type: 'date',
    placeholder: '',
    defaultValue: '',
    readonly: role === 'Staff',
    // required: true,
  },
]

const backgroundForm = (role) => [
  {
    label: 'โรงเรียน / SCHOOL',
    name: 'school',
    type: 'text',
    defaultValue: '',
    readonly: role === 'Staff',
    required: false,
  },
  {
    label: 'ระดับชั้น / SCHOOL LEVEL',
    name: 'level',
    type: 'text',
    defaultValue: '',
    readonly: role === 'Staff',
    required: false,
  },
  // {
  //   label: 'ทราบข้อมูล KIDSPLUS จาก /  KNOW KIDSPLUS FROM',
  //   name: 'from',
  //   type: 'text',
  //   defaultValue: '',
  //   readonly: role === 'Staff',
  //   required: true,
  // },
]
const knowKidsPlusForm = (role) => [
  {
    label: 'ทราบข้อมูล KIDSPLUS จาก /  KNOW KIDSPLUS FROM',
    name: 'from',
    type: 'checkbox',
    defaultValue: [],
    readonly: role === 'Staff',
    required: false,
    options: ['Facebook'],
  },
  {
    label: '',
    name: 'from',
    type: 'checkbox',
    defaultValue: [],
    readonly: role === 'Staff',
    required: false,
    options: ['Instagram'],
  },
]

const parent1Form = (role) => [
  {
    label: `ชื่อ - นามสกุล ผู้ปกครอง / PARENT'S  FULL NAME`,
    name: 'parentName1',
    type: 'text',
    defaultValue: '',
    readonly: role === 'Staff',
    required: true,
  },

  {
    label: 'เบอร์ติดต่อ / PHONE NUMBER',
    name: 'parentTel1',
    type: 'number',
    defaultValue: '',
    readonly: role === 'Staff',
    required: true,
    rules: {
      maxLength: {
        value: 10,
        message: 'เบอร์โทรศัพท์ 10 หลักเท่านั้น',
      },
      minLength: {
        value: 10,
        message: 'เบอร์โทรศัพท์ 10 หลักเท่านั้น',
      },
    },
  },
  {
    label: 'Email',
    name: 'parentEmail1',
    type: 'email',
    defaultValue: '',
    readonly: role === 'Staff',
    required: false,
    rules: {
      pattern: {
        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
        message: 'Invalid email address',
      },
    },
  },
  {
    label: 'อาชีพ / OCCUPATION',
    name: 'parentOccupation1',
    type: 'text',
    defaultValue: '',
    readonly: role === 'Staff',
    required: false,
  },
  {
    label: 'ความสัมพันธ์ / RELATIONSHIP',
    name: 'parentRelation1',
    type: 'text',
    defaultValue: '',
    readonly: role === 'Staff',
    required: false,
  },
]
const parent2Form = (role) => [
  {
    label: `ชื่อ - นามสกุล ผู้ปกครอง / PARENT'S  FULL NAME`,
    name: 'parentName2',
    type: 'text',
    defaultValue: '',
    readonly: role === 'Staff',
    required: false,
  },

  {
    label: 'เบอร์ติดต่อ / PHONE NUMBER',
    name: 'parentTel2',
    type: 'number',
    defaultValue: '',
    readonly: role === 'Staff',
    required: false,
    rules: {
      maxLength: {
        value: 10,
        message: 'เบอร์โทรศัพท์ 10 หลักเท่านั้น',
      },
      minLength: {
        value: 10,
        message: 'เบอร์โทรศัพท์ 10 หลักเท่านั้น',
      },
    },
  },
  {
    label: 'Email',
    name: 'parentEmail2',
    type: 'email',
    defaultValue: '',
    readonly: role === 'Staff',
    required: false,
    rules: {
      pattern: {
        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
        message: 'Invalid email address',
      },
    },
  },
  {
    label: 'อาชีพ / OCCUPATION',
    name: 'parentOccupation2',
    type: 'text',
    defaultValue: '',
    readonly: role === 'Staff',
    required: false,
  },
  {
    label: 'ความสัมพันธ์ / RELATIONSHIP',
    name: 'parentRelation2',
    type: 'text',
    defaultValue: '',
    readonly: role === 'Staff',
    required: false,
  },
]

const parentExtraForm = (role) => [
  {
    label: 'ชื่อ-นามสกุล / FULL NAME',
    name: 'parentNameExtra',
    type: 'text',
    defaultValue: '',
    readonly: role === 'Staff',
    required: false,
  },

  {
    label: 'เบอร์ติดต่อ / PHONE NUMBER',
    name: 'parentTelExtra',
    type: 'number',
    defaultValue: '',
    readonly: role === 'Staff',
    required: false,
    rules: {
      maxLength: {
        value: 10,
        message: 'เบอร์โทรศัพท์ 10 หลักเท่านั้น',
      },
      minLength: {
        value: 10,
        message: 'เบอร์โทรศัพท์ 10 หลักเท่านั้น',
      },
    },
  },
  {
    label: 'อาชีพ / OCCUPATION',
    name: 'parentOccupationExtra',
    type: 'text',
    defaultValue: '',
    readonly: role === 'Staff',
    required: false,
  },
  {
    label: 'ความสัมพันธ์ / RELATIONSHIP',
    name: 'parentRelationExtra',
    type: 'text',
    defaultValue: '',
    readonly: role === 'Staff',
    required: false,
  },
]

export {
  infoForm1,
  infoForm2,
  backgroundForm,
  parent1Form,
  parent2Form,
  parentExtraForm,
  knowKidsPlusForm,
}
