import {
  Box,
  Checkbox,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  SimpleGrid,
  Text,
} from '@chakra-ui/react'
import { Controller } from 'react-hook-form'

function KidsKnowForm({ role, control, errors, data, watchKnow, register }) {
  const knowOptions = [
    {
      id: 'doctor',
      label: 'แพทย์ / บุลคลากรทางการแพทย์',
      hasInput: true,
    },
    { id: 'lineOA', label: 'Line OA', hasInput: false },
    { id: 'friend', label: 'เพื่อน / ญาติ', hasInput: false },
    { id: 'youtube', label: 'YouTube', hasInput: false },
    { id: 'teacher', label: 'ครู / โรงเรียน', hasInput: true },
    { id: 'tiktok', label: 'TikTok', hasInput: false },
    { id: 'facebook', label: 'Facebook / Inbox', hasInput: false },
    { id: 'instagram', label: 'Instagram', hasInput: false },
    { id: 'messenger', label: 'Messenger', hasInput: false },
    {
      id: 'advertising',
      label: 'ป้ายโฆษณา / ขับรถผ่าน / Walk in',
      hasInput: false,
    },
    { id: 'google', label: 'Google', hasInput: false },
    { id: 'other', label: 'อื่นๆ', hasInput: true },
    { id: 'website', label: 'Website', hasInput: false },
  ]

  return (
    <Box>
      <FormLabel my={3}>
        <HStack spacing={0}>
          <Text>
            ทราบข้อมูล KIDS PLUS จาก / KNOW KIDS PLUS FROM (ตอบได้มากกว่า 1 ข้อ)
          </Text>
        </HStack>
      </FormLabel>

      <SimpleGrid
        columns={[1, 1, 2, 2]}
        spacingX={[1, 1, 1, '25px']}
        justifyContent='center'
        alignItems='center'
        spacing={6}
      >
        {knowOptions?.map((option, index) => (
          <FormControl
            key={index}
            isInvalid={errors?.know?.[option?.id]?.detail?.message}
          >
            <HStack>
              <Controller
                name={`know.${option?.id}.selected`}
                id={`know.${option?.id}.selected`}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Checkbox
                    colorScheme='lime'
                    isChecked={value}
                    onChange={onChange}
                    defaultChecked={data?.know?.[option?.id]?.selected || false}
                  />
                )}
              />
              <Text>{option?.label}</Text>
              {option?.hasInput && (
                <Input
                  id={`know.${option?.id}.detail`}
                  {...register(`know.${option?.id}.detail`, {
                    required:
                      option?.hasInput && watchKnow?.[option?.id]?.selected
                        ? `This field is required`
                        : undefined,
                  })}
                  errorBorderColor='red'
                  placeholder={
                    option?.hasInput &&
                    watchKnow?.[option?.id]?.selected &&
                    !watchKnow?.[option?.id]?.detail
                      ? 'โปรดระบุ'
                      : ''
                  }
                  w={'70%'}
                  name={`know.${option?.id}.detail`}
                  defaultValue={data?.know?.[option?.id]?.detail || ''}
                  isRequired={
                    option?.hasInput && watchKnow?.[option?.id]?.selected
                  }
                />
              )}
            </HStack>
            <FormErrorMessage>
              {errors?.know?.[option?.id]?.detail?.message}
            </FormErrorMessage>
          </FormControl>
        ))}
      </SimpleGrid>
    </Box>
  )
}

export default KidsKnowForm

/*
 <Controller
   name={`know.${option.id}.detail`}
   id={`know.${option.id}.detail`}
   control={control}
   defaultValue=''
   rules={{
     required:
       option?.hasInput && watchKnow?.[option?.id]?.selected
         ? `This field is required`
         : undefined,
   }}
   render={({ field: { onChange, value } }) => (
     <Input
       w={'70%'}
       value={value}
       onChange={onChange}
       isRequired={
         option?.hasInput && watchKnow?.[option?.id]?.selected
       }
       placeholder={
 option?.hasInput && watchKnow?.[option?.id]?.selected
   ? 'โปรดระบุ'
   : ''
       }
     />
   )}
 />
*/
